import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants'
import {TypeAnimation} from 'react-type-animation';
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo.png'


const HeroSection = () => {
  return (
       <div className='min-h-[85vh] lg:min-h-[78vh] flex items-center color-white bg1 margin-none' id='home'>
      <div className="container mx-auto">
        <HeroContent />
      </div>
    </div>
  );
};

const HeroContent = () => {
  return (
    <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
      <HeroText />
      <HeroImage />
    </div>
  );
};

const HeroText = () => {
  return (
    <div className='flex-1 deGray text-center font-secondary lg:text-left'>
      <motion.h1
        variants={fadeIn('up', 0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.4 }}
        className='text-[55px] font-bold leading-[0.8] lg:text-[60px] bh1'>
        GUT BEDACHT AUS PLÖN <span className='bh2 lg:text-[40px]'>BEHREND-DACH IN PLÖN SAGT</span>
      </motion.h1>

      <motion.div
        variants={fadeIn('up', 0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.4 }}
        className='mb-6 text-[36px] lg:text-[60px] pf uppercase leading-[1]'>
        <span className='mr-4'>...</span>
        <TypeAnimation
          sequence={['Moin, Moin', 2000, 'Hallo', 2000, 'Hi', 2000]}
          speed={50}
          className='pf font-bold'
          wrapper='span'
          repeat={Infinity}
        />
      </motion.div>

      <motion.p
        variants={fadeIn('up', 0.6)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.4 }}
        className='mb-8 max-w-lg mx-auto lg:mx-0 font-bold'>
        Hallo und herzlich willkommen auf unserer Website. Überzeugen Sie sich von unserer Qualität und Leistung auf dieser interaktiven Info-Website!
      </motion.p>

      <Link to="/Kontakt">
        <motion.button
          variants={fadeIn('up', 0.7)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.4 }}
          className='btn btn-sm rounded-2xl font-bold text-[26px] borders'>
          In Kontakt treten
        </motion.button>
      </Link>
    </div>
  );
};

const HeroImage = () => {
  return (
    <motion.div
      variants={fadeIn('down', 0.5)}
      initial="hidden"
      whileInView={'show'}
      className='lg:flex flex-1 max-w-[320px] mb-5 Logo lg:max-w-[482px]'>
      <img src={Logo} alt='Company Logo' />
    </motion.div>
  );
};

export default HeroSection;