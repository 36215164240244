import React from 'react';
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import newDach from '../assets/Neuaufbau.jpg'
import Ref2 from '../assets/Ref2.jpeg'
import Ref3 from '../assets/Ref3.jpeg'
import Ref4 from '../assets/Saniert.jpg'
import { Helmet } from 'react-helmet';


const Ref = () => {
  

  
  return ( 
   <> 
   <Helmet>
                <meta charSet="utf-8" />
                <title>Referenzen</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Referenzen" />
            </Helmet>
  <div id="scroll-container" >
    <div id="scroll-text" className=" headline text-[55px]">REFERENZEN</div>
 </div>
 <div className='h-[50px]'></div>
 <div className='text-center'>
 <a className='bh2 text-[25px] font-bold '>Hier sind einige unserer letzten Bauprojekte als Referenzen für unsere Arbeit.</a>
 <div className='h-[75px]'></div>
 </div>
    <div className="container mx-auto mb-20">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' src={newDach}/>
          </motion.div>
        {/* text */}
        <div className='flex-1 text-center  lg:text-left text-black'>
        <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[35px] font-bold bh2 leading-[1.2]'>
        Einfache Sanierung eines Steildaches
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-20 mx-auto leading-[1.4] lg:mx-0 text-[16px]'>
            -Energetische Dachsanierung mit einer PIR-Aufdachdämmung, 160 mm dick <br/>
-Eindeckung mit Meyer-Holsen, Rundschnitt Biber, Graphit, Doppeldeckung <br/>
-Schornsteinkopf mit Schiefer, 30/30 cm, Bogenschnitt, waagerecht gedeckt <br/>
-Schornsteinkopfeinfassung mit Blei-Schichtstücken ausgebildet, Schürze und Nackenkehle eingefalzt <br/>
-Traufen und Ortgänge geschiefert
            </motion.p>
        </div>
      </div>
    </div>
    {/* ref2 */}
    <div className="container mx-auto mb-20">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12 '>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' src={Ref2}/>
          </motion.div>
        {/* text */}
        <div className='flex-1 text-center  lg:text-left text-black'>
        <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[35px] font-bold bh2 leading-[1.2]'>
        Marineunteroffiziersschule IN PLÖN
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-20 mx-auto leading-[1.4] lg:mx-0 text-[16px]'>
            -Diese ehemals als ungedämmte konzipierte Werkhalle wurde dach- und fassadentechnisch komplett saniert.<br/>
-Die vorhandene Dacheindeckung aus einschaligen Stahlwellprofilen wurde demontiert und durch ein hochdämmendes Verbundelement, bestehend aus kunststoffbeschichteten Stahlblechdeckschalen mit einem Polyurethan-Kern, Kerndicke: 100 mm (Gesamtdicke: 135 mm), ersetzt.<br/>
-Die Fassadenverkleidung, die ebenfalls im Bestand aus einem einschaligem Wellprofil vorhanden war, wurde durch ein hochdämmendes Verbundelement, bestehend aus kunststoffbeschichteten Stahlblechdeckschalen mit einem Polyurethan-Kern, Kerndicke: 100 mm, ersetzt.<br/>
-Schornsteinkopfeinfassung mit Blei-Schichtstücken ausgebildet, Schürze und Nackenkehle eingefalzt. <br/>
-Als Belichtungsfläche wurde eine durchgängige Lichtbandlösung aus mehrschichtigen Polycarbonat-Lichtplatten mit thermisch getrennten Aluminiumprofilen realisiert.
            </motion.p>
        </div>
      </div>
    </div>
    <div className="container mx-auto mb-20">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' src={Ref3}/>
          </motion.div>
        {/* text */}
        <div className='flex-1 text-center  lg:text-left text-black'>
        <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[35px] font-bold bh2 leading-[1.2]'>
        Montage einer 10-KWp-Anlage in einem alten Hohlpfannendach.
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-20 mx-auto leading-[1.4] lg:mx-0 text-[16px]'>
            
            </motion.p>
        </div>
      </div>
    </div>
    <div className="container mx-auto mb-20">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' src={Ref4}/>
          </motion.div>
        {/* text */}
        <div className='flex-1 text-center  lg:text-left text-black'>
        <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[35px] font-bold bh2 leading-[1.2]'>
        Energetische Sanierung eines Einfamilienwohnhauses
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-20 mx-auto leading-[1.4] lg:mx-0 text-[16px]'>
            -Die vorhandene Dacheindeckung aus Betondachsteinen einschließlich Dachlattung sowie die vorhandene Glaswolle wurden zurückgebaut.<br/>
-Eine luftdichte Gebäudehülle wurde erstellt, anschließend wurde das Dach mit Polyurethan-Dämmplatten als Aufdachdämmung gedämmt.<br/>
-Die Giebel sowie Traufen wurden mit Schiefer verkleidet, die Unterschläge mit einer hellgrauen Schichtstoffplatte.<br/>
-Die Eindeckung erfolgte mit einem hochwertigen Ziegel der Firma Meyer-Holsen, Typ Zwilling natur.
            </motion.p>
        </div>
      </div>
    </div>
			
  

<div className='h-[300px]'></div>

</>
)
}

export default Ref


 
