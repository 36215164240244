import React from "react"; 
import { Link} from "react-router-dom"
import Insta from '../src/assets/Insta.webp'
 

const Footer = () => {
    return(
        <footer className="flex">
        <div class="container">
          <ul>
            <li><div  className="font-bold">Behrend-Dach GmbH</div></li>
            <li><div  >Dachdeckermeister Daniel Bartnik</div></li>
            <li><div  >24306 Plön - Lübecker Str. 4</div></li>
            <li><a href="tel:+4904522802960">Telefon: 04522 802 960</a></li>
            <li><a href="mailto:info@behrend-dach.de">E-mail: info@behrend-dach.de</a></li>
            <li><div ><Link to="/Impressum"  >
        Impressum
        </Link><br/>
        <Link to="/Datenschutz">
       Datenschutz </Link></div></li>
          </ul>
        </div>
        <div className="flex-col">
        <h1 className="text-[30px] mob">Bewerten Sie uns gerne <a href="https://www.google.de/search?client=safari&sca_esv=faf1bece9f668766&sca_upv=1&hl=de-de&sxsrf=ADLYWILtM910zT6uLbZ3i8XN3m8tzm13bQ:1724613528452&q=rezensionen%20f%C3%BCr%20behrend-dach%20gmbh%20d%C3%A4cher%20-%20fassaden%20-%20abdichtungen%20pl%C3%B6n&ludocid=2508461077091643373&ibp=gwp%3B0%2C7&sa=X&ved=0CAsQ5foLahcKEwjYsuWG7pCIAxUAAAAAHQAAAAAQBA#lkt=LocalPoiReviews&arid=ChZDSUhNMG9nS0VJQ0FnSUQwdHVYakhnEAE&lpg=cid:CgIgAQ%3D%3D" className="bh1 font-bold">hier.</a></h1>
        <h1 className="text-[30px] desk">Bewerten Sie uns gerne <a href="https://www.google.de/search?client=safari&sa=X&sca_esv=faf1bece9f668766&hl=de-de&tbm=lcl&sxsrf=ADLYWIIHEMzqKdV4z28ApdKzFkqj8zrlgQ:1729758593881&q=Behrend-Dach+GmbH+D%C3%A4cher+-+Fassaden+-+Abdichtungen+Rezensionen&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MjWwMDEzNDA3N7A0NDMxNjY33sDI-IrR3ik1oyg1L0XXJTE5Q8E9N8lDweXwkuSM1CIFXQW3xOLixJTUPCDTMSklMzmjpDQvHcgNSq1KzSvOzM9LzVvESqkJACt0kYmeAAAA&rldimm=2508461077091643373&ved=2ahUKEwiggqT0zKaJAxXnm_0HHYcGEykQ9fQKegQILxAF&biw=1920&bih=917&dpr=1#lkt=LocalPoiReviews&lrd=0x47b267fec65a6bc9:0x22cfd80f28f0efed,3,,,," className="bh1 font-bold">hier.</a></h1>
        <div className="h-[50px]"/>
        <h1 className="text-[30px]">Folgen Sie uns auf Social Media.</h1>
        <a href='https://instagram.com/behrenddach?igshid=YmMyMTA2M2Y='>
          <img src={Insta} alt='insta' className='insta'/>
          </a>
        </div>
      </footer>
    )
}

export default Footer;

