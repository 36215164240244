import React from 'react';
import News2 from '../assets/Gesel2.png'
import News3 from '../assets/Boecker_Zertifikat_KRANPROFI_2024_Behrend_Florian Fischer.1.png'
import News4 from '../assets/Boecker_Zertifikat_Kranfuehrer_2024_Behrend.1.png'
import News5 from '../assets/Meisterhaft2025.png'
import News6 from '../assets/Gesel.jpg'
import { Helmet } from 'react-helmet';
import {motion} from 'framer-motion' 
import { fadeIn } from '../variants';

const Leistungen = () => {
 
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Leistungen</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Leistungen" />
            </Helmet>
            <div className="container mx-auto">
        <div className='flex-1 text-center font-secondary '>
          <motion.div variants={fadeIn('right', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='lg:text-[50px] text-[40px] font-bold bh2 leading-[1] '>  
          Hier sind einige Leistungen, Zertifikate und andere wichtige Informationen aus letzter Zeit von Behrend Dach.
            </motion.div>  
        </div>
    </div >
    <div className='h-[50px]'/>
  <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row gap-x-10 '>
            <motion.div 
            
            variants={fadeIn('right', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
              {/*text*/}
              <div className='desk'>
                <h2 className='h2 leading-tight bh1 font-bold text-[70px]'>
                {/* titel wenn es nötig ist */}<br/>  
                </h2>
                <p className='max-w-sm mb-16 pfs'>  
                </p> 
              </div>  
              
              <div className='h-[50px]'></div>
              <motion.div whileTap={{ scale: 2.5,zIndex: 100, position: 'relative',
                left: '15vw', }} className='shadow rounded-2xl'>
               <div className='group relative overflow-hidden border-2 border-black/50  rounded-2xl  '>
                   {/*overlay*/}
                  <div className=' w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className=' transition-all duration-500' src={News6} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 contrast group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Wir gratulieren zur bestandenen Gesellenprüfung</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  </div>
                  
               </div>     
               </motion.div>
              </motion.div>
            <motion.div className='flex-1 flex flex-col gap-y-10' 
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            >
              {/*image*/}
              <motion.div whileTap={{ scale: 2.5,zIndex: 100, position: 'relative',
                left: '-15vw', }} className='group relative overflow-hidden border-2 border-black/50 rounded-xl shadow '>
                   {/*overlay*/}
                  <div className='w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className=' transition-all duration-500' src={News2} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 contrast group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Unsere neuesten Gesellen!</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                   
                  </div>
               </motion.div> 
               <motion.div whileTap={{ scale: 3,zIndex: 100, position: 'relative',
                left: '-15vw', }} className='group relative overflow-hidden border-2 border-black/50 rounded-xl bg-white'>
                   {/*overlay*/}
                  <div className=' w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className=' transition-all duration-500' src={News5} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 contrast group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Urkunde<br/> 2025</span> 
                  </div>
                  {/* title */}
                  
               </motion.div>     
            </motion.div>
          </div>
          <motion.div variants={fadeIn('', 0.3)}
            initial="hidden"
            whileInView={"show"} className='z-100 w-100% left-2/3 relative transition-all top-2 h-[60px] duration-700'>
                  <button className=' text-white  btn btn-sm'><a href='https://www.meisterhaft.info/handwerker/behrend-dach-gmbh-dachdeckermeister-danielbartnik/'>Mehr Info</a></button>
                  </motion.div>
          <div className='flex flex-col lg:flex-row gap-x-10'>
            <motion.div variants={fadeIn('right', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
          <motion.div whileTap={{ scale: 3,zIndex: 100, position: 'relative',
                left: '15vw',
                top: '-30vh' }} className='group relative overflow-hidden border-2 border-black/50 rounded-xl bg-white'>
                   {/*overlay*/}
                  <div className='w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className=' transition-all duration-500' src={News3} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 contrast group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>KRANPROFI<br/> Zertifikat 2024</span> 
                  </div>
                  {/* title */}
                  
               </motion.div>     
            </motion.div>
            <div className='h-[40px]'></div>
            <motion.div variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
          <motion.div whileTap={{ scale: 3,zIndex: 100, position: 'relative',
                left: '-15vw',
                top: '-30vh', }} className='group relative overflow-hidden border-2 border-black/50 rounded-xl bg-white'>
                   {/*overlay*/}
                  <div className=' w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className=' transition-all duration-500' src={News4} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 contrast group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Kranfüherer <br/>Zertifikat 2024</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  <span className='text-3x1 text-[20px] text-white'></span>
                  </div>
               </motion.div>     
            </motion.div>
          </div>
        </div>
        <div className='h-[100px]'></div>
    </>
  );
};

export default Leistungen;