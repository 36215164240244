import React from 'react';
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import Dach9 from '../assets/Dach9.webp'
import Dach10 from '../assets/Dach10.webp'
import HeroSection from './hero';

import oldDach from '../assets/oldDach.webp'
import newDach from '../assets/newDach.webp'
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage
} from "react-compare-slider";
import {Ticker1, Ticker2} from './components/Ticker';
import { Helmet } from 'react-helmet';

const Home = ( style, ...props ) => {
 
  return(
    <>
    <HeroSection/>
    <div className='h-[50px]'></div>
    <div className="container mx-auto mb-40">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' src={Dach10}/>
          </motion.div>
        {/* text */}
        <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='flex-1 text-center glasscard lg:text-left text-black'>
        <motion.h2 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='text-[30px] font-bold bh2 leading-[1.2]'>
        Allgemein
            </motion.h2>
        <motion.h1 variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='text-[45px] mb-5 font-bold bh1 leading-[1.2]'>
        Unsere Arbeit
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className=' mx-auto font-bold leading-[1.4] lg:mx-0 text-[20px]'>
            Wir beschäftigen uns mit dem gesamten Spektrum des Dachdeckerhandwerks, führen Kleinstreparaturen aus wie das Austauschen einer einzelnen Pfanne, bauen Dachfenster und Photovoltaikanlagen ein, führen aber natürlich auch energetische Sanierungen im Steil- und Flachdach aus. <br/><br/>
            Auch Blecharbeiten wie Dachentwässerung, Einfassungen und Stehfalz gehören zu unserem Aufgabenspektrum.
            </motion.p>
        </motion.div>
      </div>
    </div>
    <div className="container mx-auto mb-20">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* text */}
        <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='flex-1 text-center glasscard lg:text-left text-black'>
        <motion.h2 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[30px] font-bold bh2 leading-[1.2]'>
        Nicht Ihr gewöhnlicher Dachdecker
            </motion.h2>
        <motion.h1 variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[45px] mb-5 font-bold bh1 leading-[1.2]'>
        Unsere Qualität
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className=' mx-auto font-bold leading-[1.4] lg:mx-0 text-[20px]'>
            Wir achten auf hohe Qualitätsstandards und verarbeiten hochwertige Produkte unserer<br/>
 <a className='bh1' href='https://www.behrend-dach.de/Partnerfirmen'>-Partnerfirmen.</a> <br/><br/>
Unsere Mitarbeiter nehmen regelmäßig an Schulungen teil.
            </motion.p>
        </motion.div>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.4)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' src={Dach9}/>
          </motion.div>
      </div>
    </div>
         
         <div className='h-[300px] spc'/>
         <Ticker1/>
      <div className='h-[50px]'></div>
    <Ticker2/>
      
    
  
  <div className='h-[200px]'></div>
  <motion.div variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} >
  <div className='container mx-auto'>
  <h1 className='bh2 text-[28px] h-[60px] text-center'>Überzeugen Sie sich selbst!</h1>
  <h2 className='bh1 text-[40px] font-bold h-[130px] text-center  leading-10'>Mit einem Vorher-Nachher-Effekt einer Sanierung</h2>
  </div>
  
  <ReactCompareSlider className='container mx-auto shadow1' 
      {...props}
      handle={
        <ReactCompareSliderHandle
          buttonStyle={{
            backdropFilter: undefined,
            background: "white",
            border: 0,
            color: "#333"
          }}
        />
      }
      itemOne={
        <ReactCompareSliderImage
          src={oldDach}
          alt="one" 
        />
       
      }
      itemTwo={
        
        <ReactCompareSliderImage
          src={newDach}
          alt="two"
        /> 
      }
      style={{
        display: "flex",
        width: "100%",
        height: "50vh",
        ...style
      }}
    />
    
        <div className='h-[100px]'></div>
        <motion.div variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
        <h2 className='bh1 text-center text-[40px] font-bold h-[120px] leading-10'>Fördermöglichkeiten für Dachfenster der Firma Roto</h2>
        <a  href="https://www.roto-foerderservice.de/Startseite?action=c6925dfd-9255-415e-a028-fa2d488714cd" target="_blank"><img className='resize2' alt='' src="https://www.roto-foerderservice.de/images/banner2.jpg" /></a>
     </motion.div>
    
    
   
    </motion.div><div className='h-[100px]'></div>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Home" />
            </Helmet>
  </>
  );
}; 

export default Home;


