import Home from "./pages/Home"
import Navbar from "./Navbar"
import Über from "./pages/Über_uns"
import Partner from "./pages/Partnerfirmen"
import Ref from "./pages/Refferenzen"
import Kontakt from "./pages/Kontakt"
import Footer from "./Footer"
import Impressum from "./pages/Impressum"
import Datenschutz from "./pages/Datenschutz"
import Leistungen from "./pages/News"
import { Example } from "./Example";
import { Route, Routes } from "react-router-dom"
import MediaQuery from 'react-responsive';
import React from 'react'
import Karriere from "./pages/Karriere"

const NotFound = () => {
  return <div className="text-center font-bold leading-10 text-[50px] mb-20 mt-20"> <h1 className="text-black">404 Not Found</h1>
  <p className="text-black">Die von Ihnen gesuchte Seite konnte nicht gefunden werden.</p>
  <p><a className="bh2" href="https://www.behrend-dach.de/"> Zurück zur Homepage</a></p>
  </div>;
};

function App() {
  return (
    <>
      <MediaQuery query="(min-width: 1024px)">
    <Navbar />
</MediaQuery>

<MediaQuery query="(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)">
    <Navbar />
</MediaQuery>

<MediaQuery query="(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)">
    <Example />
</MediaQuery>

<MediaQuery query="(max-width: 768px) and (orientation: landscape)">
    <Example />
</MediaQuery>

<MediaQuery query="(max-width: 768px) and (orientation: portrait)">
    <Example />
</MediaQuery>

     
     
      <div className=' overflow-hidden'>
      <div className='h-[50px]' ></div>
      <Routes>      
          <Route path="/Home" element={<Home />} />
          <Route path="/" element={<Home />} />  
          <Route path="/Über_uns" element={<Über />} />
          <Route path="/Partnerfirmen" element={<Partner />} />
          <Route path="/Referenzen" element={<Ref />} />
          <Route path="/Kontakt" element={<Kontakt />} />
          <Route path="/Impressum" element={<Impressum/>} />
          <Route path="/Datenschutz" element={<Datenschutz/>} />
          <Route path="/Leistungen" element={<Leistungen/>} />
          <Route path="/Karriere" element={<Karriere/>} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
      
      <Footer/>
    </>
  )

}


export default App